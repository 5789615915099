// var OSS = require('ali-oss');
import OSS from "ali-oss";
export function client(){
	let Herfenv=JSON.parse(sessionStorage.getItem("queryPlatform")).env
	// ||window.location.href.indexOf('http://192.168.1.136:1070') != -1 智云购修改上线加上
	if (Herfenv!='dev'&&Herfenv!='beta') {
		console.log('线上')
		var client = new OSS({
		    endpoint: 'oss-accelerate.aliyuncs.com',//填写Bucket所在地域
		    accessKeyId: 'LTAI4FeWBqBbWrSaoKLX2t4Y',
		    accessKeySecret: 'PWcO8QcFftAog5Zh8LsGiSx8bDj5XX',
		    bucket: '1yunhui-bj',// 填写Bucket名称。
		})  //后端提供数据 
	} else {
		console.log('测试')
		var client = new OSS({
		    endpoint: 'oss-accelerate.aliyuncs.com',//填写Bucket所在地域
		    accessKeyId: 'LTAI4FuxVGz7JCfeh4oLZYUf',
		    accessKeySecret: 'egqdA3S1Ty10ZlYmQ2RANRpGKFG94l',
		    bucket: '1yunhui-qa',// 填写Bucket名称。
		})  //后端提供数据 
	}
  
  return client
}
/**
 * 生成随机uuid
 */
export const getFileNameUUID = () => {
  function rx() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return `${+new Date()}_${rx()}${rx()}`
}

